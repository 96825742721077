/** @jsx jsx */
import { jsx, Box, Text, Link, Button } from "theme-ui"
import { Fragment, useCallback } from "react"
import { graphql, Link as GatsbyLink, navigate } from "gatsby"

import BlogPost from "../components/BlogPost"
import TopNavigation from "../components/TopNavigation"

import Layout from "../layout"

function Pagination(props) {
  const { prev, next } = props
  return (
    <nav sx={{ marginTop: "3rem", padding: "0px 1rem" }}>
      <div
        sx={{
          paddingRight: "1rem",
          boxSizing: "border-box",
          display: "inline-block",
          verticalAlign: "top",
          width: "50%",
        }}
      >
        {next && (
          <Fragment>
            <Text sx={{ display: "block" }}>Newer Post</Text>
            <Link to={next.url} as={GatsbyLink}>
              {next.title}
            </Link>
          </Fragment>
        )}
      </div>
      <div
        sx={{
          textAlign: "right",
          paddingRight: "1rem",
          boxSizing: "border-box",
          display: "inline-block",
          verticalAlign: "top",
          width: "50%",
        }}
      >
        {prev && (
          <Fragment>
            <Text sx={{ display: "block" }}>Older Post</Text>
            <Link to={prev.url} as={GatsbyLink}>
              {prev.title}
            </Link>
          </Fragment>
        )}
      </div>
    </nav>
  )
}

function Post({ data, pageContext }) {
  const { prev, next } = pageContext

  // const handleOnBack = useCallback(() => {
  //   navigate(-1)
  // }, [])

  return (
    <Layout title={data.post.title}>
      <TopNavigation />
      {/* <Box>
        <Button variant="text" onClick={handleOnBack}>
          Posts
        </Button>
      </Box> */}
      <Box pt={3}>
        <BlogPost post={data.post} />
        <Pagination prev={prev} next={next} />
      </Box>
    </Layout>
  )
}

export default Post

export const postQuery = graphql`
  query($id: String!) {
    post: ghostPost(id: { eq: $id }) {
      slug
      id
      title
      excerpt
      reading_time
      published_at(formatString: "DD MMMM YYYY")
      authors {
        id
        name
      }
      feature_image
      html
    }
  }
`
